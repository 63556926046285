





















import router from "@/router";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CardInfo",
  props: {
    info: String,
    subtitle: String,
    appName: String,
    targetName: String,
  },
  setup(props) {
    const url = computed(() => router.resolve({ name: props.targetName }).href);
    const navigateToTarget = () => {
      const routeTo = { name: props.targetName };
      router.push(routeTo);
    };
    return {
      url,
      navigateToTarget,
    };
  },
});
